<script setup lang="ts">import { toRef as _toRef, ref as _ref } from 'vue';

import { NuxtImg } from '#components'

const { $ui } = useNuxtApp()
const { user } = useLoginStore()

const __$temp_1 = (useScroll(document)),
  y = _toRef(__$temp_1, 'y');

const colorMode = useColorMode()
let colorModeChanging = _ref(false)
const reset = useDebounce(() => colorModeChanging.value = false)
watch(colorMode, () => {
  colorModeChanging.value = true
  reset()
})

const router = useRouter()
let back = _ref(false)
watch(router.currentRoute, () => back.value = !!router.options.history.state.back)

const links = [
  {
    label: '成员',
    icon: 'tdesign:member',
    to: '/member',
  },
  {
    label: '专栏',
    icon: 'heroicons:newspaper',
    to: '/blog',
  },
  {
    label: '控制台',
    icon: 'material-symbols:space-dashboard-outline-rounded',
    to: '/dashboard',
  },
]
</script>

<template>
  <UHeader
    :class="$route.path === '/' ? {
      'transition duration-1000': !colorModeChanging,
      'bg-transparent !border-transparent': y <= 64,
    } : {}"
    :ui="{
      left: 'flex-1', right: 'flex-1',
    }"
  >
    <template #left>
      <div class="w-8" />

      <ClientOnly>
        <UButton
          v-show="back"
          v-bind="$ui?.button?.secondary"
          class="btn btn-sm btn-square btn-ghost absolute"
          icon="material-symbols:arrow-back-ios-new-rounded"
          @click="router.back()"
        />
      </ClientOnly>

      <NuxtLink class="btn btn-sm btn-ghost max-lg:hidden" to="/">
        <NuxtImg
          class="h-7"
          src="1441947b49b2e92d64c51acceeaf993b.png"
          height="56"
          alt="Hanabi_Live"
        />
      </NuxtLink>
    </template>

    <template #center>
      <NuxtLink class="btn btn-sm btn-ghost lg:hidden" to="/">
        <NuxtImg
          class="h-7"
          src="1441947b49b2e92d64c51acceeaf993b.png"
          height="56"
          alt="Hanabi_Live"
        />
      </NuxtLink>

      <UHeaderLinks class="hidden lg:flex" :links />
    </template>

    <template #right>
      <UColorModeButton class="btn btn-sm btn-square btn-ghost max-lg:hidden" />

      <NuxtLink class="hidden lg:flex ml-2" to="/login">
        <UAvatar
          class="
            hover:ring-2 ring-primary
            ring-offset-1 ring-offset-gray-200 dark:ring-offset-gray-800
            hover:scale-105 transition-transform *:!text-primary *:font-bold"
          :as="NuxtImg"
          alt="登 录"
          :src="user?.face"
          size="md"
          width="64"
          referrerpolicy="same-origin"
        />
      </NuxtLink>
    </template>

    <template #panel>
      <ProfileCard
        :to="user ? '/dashboard' : '/login'"
        :title="user?.name"
        :description="user && `UID:${user.uid}`"
        :face="user?.face"
      >
        <UColorModeButton class="btn btn-sm btn-ghost btn-square" @click.stop />
      </ProfileCard>

      <UAsideLinks
        class="mx-0 mt-4"
        :links
        :ui="{
          base: 'gap-2',
          label: 'text-xl',
          icon: { base: 'size-5' },
        }"
      />
    </template>
  </UHeader>
</template>
