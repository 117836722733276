<script setup lang="ts">
const links = [
  {
    label: '社团信息',
    children: [
      {
        to: '/blog/recruitment#社团介绍',
        label: '关于 Hanabi_Live',
      },
      {
        to: '/blog/recruitment',
        label: '加入我们',
      },
      {
        to: '/blog/recruitment#伙伴支持',
        label: '合作伙伴',
      },
    ],
  },
  {
    label: '法律条款',
    children: [
      {
        to: '/blog/legal#用户协议',
        label: '用户协议',
      },
      {
        to: '/blog/legal#_3-隐私政策',
        label: '隐私政策',
      },
      {
        to: '/blog/legal#开源许可',
        label: '开源许可',
      },
    ],
  },
  {
    label: '联系我们',
    children: [
      {
        to: 'https://message.bilibili.com/#/whisper/mid3546602868312291',
        label: 'BiliBili: @Hanabi_Live',
        target: '_blank',
      },
      {
        to: 'mailto:help@hanabi-live.com',
        label: '邮箱: help@hanabi-live.com',
        target: '_blank',
      },
      {
        to: 'https://qm.qq.com/q/5ZoaKfNY9G',
        label: '非官方粉丝QQ群: 851277834',
        target: '_blank',
      },
    ],
  },
]
</script>

<template>
  <UFooter
    :ui="{ top: { container: 'relative' } }"
    :links="[{
      label: '沪ICP备2023013795号-2', to: 'https://beian.miit.gov.cn',
    }]"
  >
    <template #top>
      <UDivider class="absolute top-0 left-0 right-0 m-auto" />

      <UFooterColumns :links />

      <UDivider class="absolute bottom-0 left-0 right-0 m-auto">
        <Icon class="size-5" name="icon:hanabi-live" />
      </UDivider>
    </template>

    <template #right>
      <NuxtLink
        class="
          btn btn-sm btn-square btn-ghost not-prose text-[#00a1d6]
          [@media(hover:hover)]:text-[unset] hover:text-[#00a1d6]
        "
        to="https://space.bilibili.com/3546602868312291"
        target="_blank"
      >
        <Icon class="size-5" name="ri:bilibili-fill" />
      </NuxtLink>
    </template>

    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        Copyright © {{ new Date().getFullYear() }} Hanabi_Live.
        All rights reserved.
      </p>
    </template>
  </UFooter>
</template>
